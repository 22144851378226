import React, { Component } from 'react'
import PopupComponent from '../component'
import data from '../../data.json'

class PopupDemoComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      slowControl: 1,
      reverbMode: 'default',
      videoFx: null,
      reverbModes: data.reverbModes,
      lowPassMode: false,
      lofiTapeMode: false,
      darkMode: false,
      shareMediaOptIn: false,
      isSlowedEnabled: true,
      isFeedbackEnabled: true,
      isRateAppEnabled: true,
      isBuyCoffeeEnabled: true
    }
  }

  render () {
    const { isHeaderVisible, isPro, onUpgradeClick, onFeedbackClick, onHelpClick, onChangelogClick } = this.props
    const {
      isSlowedEnabled, slowControl, reverbMode, lowPassMode, lofiTapeMode, darkMode,
      reverbModes, videoFx, isFeedbackEnabled, isRateAppEnabled, isBuyCoffeeEnabled,
      shareMediaOptIn
    } = this.state

    return (
      <PopupComponent
        isHeaderVisible={isHeaderVisible || true}
        isSlowedEnabled={isSlowedEnabled}
        isFeedbackEnabled={isFeedbackEnabled}
        isRateAppEnabled={isRateAppEnabled}
        isBuyCoffeeEnabled={isBuyCoffeeEnabled}
        isPro={isPro}
        slowControl={slowControl}
        reverbMode={reverbMode}
        reverbModes={reverbModes}
        videoFx={videoFx}
        lowPassMode={lowPassMode}
        lofiTapeMode={lofiTapeMode}
        darkMode={darkMode}
        shareMediaOptIn={shareMediaOptIn}
        onUpgradeClick={onUpgradeClick}
        onFeedbackClick={onFeedbackClick}
        onChangelogClick={onChangelogClick}
        onHelpClick={onHelpClick}
        onSlowedToggle={(value) => { this.setState({ isSlowedEnabled: value }) }}
        onSlowControlChange={(value) => { this.setState({ slowControl: value, isSlowedEnabled: true }) }}
        onVideoFxChange={(value) => { this.setState({ videoFx: value, isSlowedEnabled: true }) }}
        onLowPassModeChange={(value) => { this.setState({ lowPassMode: value }) }}
        onLofiTapeModeChange={(value) => { this.setState({ lofiTapeMode: value }) }}
        onDarkModeChange={(value) => { this.setState({ darkMode: value }) }}
        onReverbModeChange={(value) => { this.setState({ reverbMode: value, isSlowedEnabled: true }) }}
        onShareMediaOptInChange={(value) => { this.setState({ shareMediaOptIn: value }) }}
      />
    )
  }
}

self.Slowed.Components.PopupDemo = PopupDemoComponent

export default PopupDemoComponent
