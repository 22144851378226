async function render () {
  const html = `
    <div class="banner"></div>
    
    <div class="container changelog">
      <h1>Changelog</h1>

      <h2 id="0.9.1">
        <a href="#0.9.1">v0.9.1</a>
      </h2>
      <code>
        <p>18 Feb 2024</p>
        <ul>
          <li>Fix reverb bug</li>
        </ul>
      </code>
      
      <h2 id="0.9.0">
        <a href="#0.9.0">v0.9.0</a>
      </h2>
      <code>
        <p>21 Aug 2022</p>
        <ul>
          <li>Added reddit and twitter share button</li>
          <li>Added goodbye form on uninstall</li>
          <li>Improved video fx frame processing</li>
          <li>Fixed video fx ratio border</li>
          <li>Bug fixes</li>
        </ul>
      </code>

      <h2 id="0.8.0">
        <a href="#0.8.0">v0.8.0</a>
      </h2>
      <code>
        <p>12 Sep 2021</p>
        <ul>
          <li>Added trial mode</li>
          <li>Added get help action</li>
          <li>Added give feedback action</li>
          <li>Added rate app action</li>
          <li>Added shortcut for lofi tape mode and low pass mode</li>
          <li>Moved shortcut and dark mode to small actions</li>
          <li>Fixed issues on audio and video processing</li>
        </ul>
      </code>

      <h2 id="0.7.0">
        <a href="#0.7.0">v0.7.0</a>
      </h2>
      <code>
        <p>30 Aug 2021</p>
        <ul>
          <li>Added Youtube Music support</li>
          <li>Added realtime slow control feedback</li>
          <li>Added enable button</li>
          <li>Added low pass mode</li>
          <li>Added lofi tape mode</li>
          <li>Added video fxs (vhs, tv glitch, noir, vaporwave, diplopia, and more)</li>
          <li>Changed video fx to one effect per time only</li>
          <li>Removed reverb controls</li>
        </ul>
      </code>

      <h2 id="0.6.0">
        <a href="#0.6.0">v0.6.0</a>
      </h2>
      <code>
        <p>14 Aug 2021</p>
        <ul>
          <li>Added reverb controls</li>
          <li>Added reverb modes & video fxs</li>
          <li>Added dark mode</li>
          <li>Added shortcuts</li>
          <li>Fixed missing track name in share URL</li>
        </ul>
      </code>

      <h2 id="0.5.0">
        <a href="#0.5.0">v0.5.0</a>
      </h2>
      <code>
        <p>04 Aug 2021</p>
        <ul>
          <li>Changed slowed rate</li>
          <li>Added checkout and payments</li>
          <li>Updated logo</li>
        </ul>
      </code>

      <h2 id="0.4.0">
        <a href="#0.4.0">v0.4.0</a>
      </h2>
      <code>
        <p>20 Jun 2021</p>
        <ul>
          <li>Removed moods section</li>
          <li>Added preset points to slowed slider</li>
          <li>Changed reverb to select instead of slider</li>
          <li>Renamed visual fx to video fx</li>
          <li>Changed video fx to checkboxes</li>
        </ul>
      </code>

      <h2 id="0.3.0">
        <a href="#0.3.0">v0.3.0</a>
      </h2>
      <code>
        <p>10 Jun 2021</p>
        <ul>
          <li>Added slowed slider sync with toggle</li>
          <li>Added slowed slider interaction even when toggle is disabled</li>
          <li>Added shareable link feature</li>
          <li>Added new visual fx controls</li>
          <li>Added new mood component</li>
        </ul>
      </code>

      <h2 id="0.2.0">
        <a href="#0.2.0">v0.2.0</a>
      </h2>
      <code>
        <p>07 Jun 2021</p>
        <ul>
          <li>Added popup UI</li>
          <li>Added mood feature</li>
          <li>Added reverb modes</li>
          <li>Added action icon on / off feedback</li>
        </ul>
      </code>

      <h2 id="0.1.0">
        <a href="#0.1.0">v0.1.0</a>
      </h2>
      <code>
        <p>30 May 2021</p>
        <ul>
          <li>Added reverb fx</li>
          <li>Added audio pitch shifting</li>
          <li>Added shortcuts to control playback rate and reverb fx</li>
        </ul>
      </code>

      <br/><br/>
    </div>
  `

  document.getElementById('content').innerHTML = html
}

export async function init () {
  await render()
}
