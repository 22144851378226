export function getQueryParams (search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params = {}
  hashes.map(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })

  return params
}

export function getAppURLFromHash (url) {
  const hashbangIndex = url.indexOf('#!')

  if (hashbangIndex === -1) {
    return {}
  }

  return new URL(`slowed://${url.slice(hashbangIndex + 2)}`)
}

export function upsertMetatag (property, content) {
  const metatag = document.querySelector(`meta[property="${property}"]`) || document.createElement('meta')
  metatag.setAttribute('property', property)
  metatag.setAttribute('content', content)
  document.head.appendChild(metatag)
}
