async function render () {
  const html = `
    <div class="banner"></div>
    
    <div class="container">
      <h1>Privacy</h1>
      <p>
        The extension requires browser permission to provide all of it's features when installed. 
        These permissions are necessary to manipulate Youtube's media for audio and video processing.
      </p>

      <p>
        Everytime Youtube is visited the extension injects a script responsible for media manipulation. 
        This is only done when requested by the user using the popup UI or macro keys.
      </p>

      <p>
        During it's usage, all of your data is kept locally or stored in your Google Account. All of it 
        is only accessible by you. No Youtube media or browsing data is collected by any kind. 
      </p>

      <p>
        To help improving the product and user experience, the extension may track user activity when using 
        the extension popup UI. This data is not personally identifiable and only shared to Google Analytics.
      </p>

      <p>
        To facilitate in-app purchases, the extension uses the email from current signed Google Account to 
        associate it when performing the checkout process.
      </p>

      <p>
        The extension complies to the <a href="https://developer.chrome.com/docs/webstore/program_policies/">Google Developer 
        Program Policies</a> and <a href="https://developer.chrome.com/docs/webstore/terms/">Chrome Web Store Terms of Service</a>.
      </p>
    </div>
  `

  document.getElementById('content').innerHTML = html
}

export async function init () {
  await render()
}
