import './index.css'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { init as initLanding } from '../landing'
import { init as initUpgrade } from '../upgrade'
import { init as initShare } from '../share'
import { init as initAbout } from '../about'
import { init as initHelp } from '../help'
import { init as initPrivacy } from '../privacy'
import { init as initTerms } from '../terms'
import { init as initInstall } from '../install'
import { init as initFeedback } from '../feedback'
import { init as initWelcome } from '../welcome'
import { init as initRate } from '../rate'
import { init as initBuyCoffee } from '../buycoffee'
import { init as initGoodbye } from '../goodbye'
import { init as initChangelog } from '../changelog'

async function initMonitoring () {
  return Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

async function init () {
  await initMonitoring()

  let { pathname, hash } = new URL(window.location.href)

  if (process.env.NODE_ENV === 'development') {
    pathname = hash && hash.indexOf('#!') >= 0 ? hash.split('#!')[1].split('?')[0] : pathname
  }

  if (pathname === '/upgrade') {
    await initUpgrade({ upgraded: false })
  } else if (pathname === '/upgraded') {
    await initUpgrade({ upgraded: true })
  } else if (pathname === '/share') {
    await initShare()
  } else if (pathname === '/privacy') {
    await initPrivacy()
  } else if (pathname === '/help') {
    await initHelp()
  } else if (pathname === '/feedback') {
    await initFeedback()
  } else if (pathname === '/install') {
    await initInstall()
  } else if (pathname === '/welcome') {
    await initWelcome()
  } else if (pathname === '/about') {
    await initAbout()
  } else if (pathname === '/rate') {
    await initRate()
  } else if (pathname === '/terms') {
    await initTerms()
  } else if (pathname === '/goodbye') {
    await initGoodbye()
  } else if (pathname === '/changelog') {
    await initChangelog()
  } else if (pathname === '/buycoffee') {
    await initBuyCoffee()
  } else {
    await initLanding()
  }
}

document.addEventListener('DOMContentLoaded', () => init())
